<template>
  <focus-trap :active="navActive">
    <!-- NAV HEADER -->
    <nav
      class="bleach-nav"
      :style="{ height: `${viewportHeight}px` }"
      tabindex="-1"
    >
      <div
        class="bleach-nav__header"
        :class="{ 'bleach-nav__header--inverted': isNavInverted }"
        @click="handleNavSelect"
      >
        <div class="bleach-nav__header-content flex--row-center-center">
          <div class="bleach-nav__header-items flex--row-start-center">
            <!-- Nav trigger -->
            <template v-if="GET_IS_BELOW_BREAKPOINT('lg')">
              <div class="bleach-nav__item-holder">
                <MenuControl
                  :active="navActiveElement === 'menu'"
                  @click="handleMenuToggle"
                />
              </div>
              <div
                v-if="GET_IS_BELOW_BREAKPOINT('md')"
                class="bleach-nav__item-holder"
              >
                <AccountControl :active="navActiveElement === 'login'" />
              </div>
            </template>
            <div
              v-if="GET_IS_ABOVE_BREAKPOINT('lg')"
              class="bleach-nav__item-holder"
            >
              <StoreControl
                class="bleach-nav__store-control"
                :light="isNavInverted"
              />
            </div>
          </div>
          <div
            class="bleach-nav__header-items bleach-nav__header-items--logo flex--col-center-center"
          >
            <LinkTag
              @click.native="handleNavSelect"
              class="bleach-nav__logo-link"
              :link="`/`"
            >
              <MediaItem v-if="isNavInverted" :elementId="'nav_inverted_logo'">
                <template v-slot:default="slotProps">
                  <bleach-image
                    class="bleach-nav__logo-invert"
                    v-if="
                      slotProps.mediaItem &&
                        slotProps.mediaItem.image &&
                        slotProps.mediaItem.image[0]
                    "
                    :src="slotProps.mediaItem.image[0].original_secure_url"
                    :sizes="{
                      md: ['auto', 24],
                      lg: ['auto', 26],
                      xl: ['auto', 30]
                    }"
                    contain
                    :default-size="['auto', 40]"
                    imageQuality="60"
                  />
                </template>
              </MediaItem>
              <template v-else>
                <bleach-image
                  class="bleach-nav__logo-video"
                  :src="logoLink"
                  :sizes="{
                    md: ['auto', 24],
                    lg: ['auto', 26],
                    xl: ['auto', 30]
                  }"
                  :default-size="['auto', 40]"
                  v-if="logoLink"
                  imageQuality="60"
                />
                <BleachLogo class="bleach-nav__logo" v-else />
              </template>
            </LinkTag>
          </div>
          <div class="bleach-nav__header-items flex--row-end-center">
            <div
              v-if="GET_IS_ABOVE_BREAKPOINT('md')"
              class="bleach-nav__item-holder bleach-nav__item-holder--end"
            >
              <AccountControl :active="navActiveElement === 'login'" />
            </div>
            <!-- Search trigger -->
            <div class="bleach-nav__item-holder bleach-nav__item-holder--end">
              <SearchControl
                :active="navActiveElement === 'search'"
                @click="handleSearchToggle"
              />
            </div>
            <!-- Cart -->
            <div class="bleach-nav__item-holder bleach-nav__item-holder--end">
              <CartControl @click="handleCartTrigger" />
            </div>
          </div>
        </div>
        <div class="bleach-nav__load-spinner-holder">
          <LoadSpinner />
        </div>
      </div>
      <!-- MENU LAYER -->
      <div class="bleach-nav__menu">
        <NavMenu
          :active="navActiveElement === 'menu'"
          @activated="setMenuActive"
          @select="handleNavSelect"
          ref="navMenu"
        />
      </div>

      <!-- SEARCH LAYER -->
      <div class="bleach-nav__search">
        <NavSearch
          :active="navActiveElement === 'search'"
          @activated="setSearchActive"
          @select="handleNavSelect"
          ref="navSearch"
        />
      </div>

      <!-- CART LAYER -->
      <div class="bleach-nav__cart">
        <Cart :active="navActiveElement === 'cart'" ref="cart" />
      </div>

      <div
        class="bleach-nav__cart-alert"
        :class="{
          'bleach-nav__cart-alert--std-pos': navActiveElement === 'search'
        }"
      >
        <CartAlert />
      </div>

      <!-- Interstitial -->
      <!-- <div class="bleach-nav__header-interstitial">
        <Interstitial />
      </div> -->
    </nav>
  </focus-trap>
</template>

<script>
import MenuControl from "./../../components/Navigation/MenuControl.vue"
import StoreControl from "./../../components/Navigation/StoreControl.vue"
import SearchControl from "./../../components/Navigation/SearchControl.vue"
import CartControl from "./../../components/Navigation/CartControl.vue"
import AccountControl from "./../../components/Navigation/AccountControl.vue"
import NavMenu from "./../NavigationMenu/NavigationMenu.vue"
import NavSearch from "./../NavigationSearch/NavigationSearch.vue"
import Cart from "./../Cart/Cart.vue"
import BleachLogo from "./../../assets/bleach_logo.svg"
import CartAlert from "./../../components/CartAlert.vue"
import LoadSpinner from "./LoadSpinner.vue"
// import Interstitial from "./Interstitial.vue"

import GTMAPI from "@/integration/GTMAPI"

import { mapState, mapActions, mapMutations, mapGetters } from "vuex"

export default {
  props: {},

  data: () => ({
    lastScrollPosition: null
  }),

  components: {
    MenuControl,
    StoreControl,
    AccountControl,
    CartControl,
    SearchControl,
    NavMenu,
    NavSearch,
    BleachLogo,
    CartAlert,
    Cart,
    LoadSpinner

    // Interstitial
  },

  created() {},

  methods: {
    ...mapActions("cart", ["OPEN_CART", "CLOSE_CART"]),
    ...mapMutations("ui", ["SET_NAV_ACTIVE_ELEMENT"]),

    handleMenuToggle() {
      this.setMenuActive(this.navActiveElement !== "menu")
    },
    handleSearchToggle() {
      this.setSearchActive(this.navActiveElement !== "search")
    },
    handleCartTrigger() {
      if (
        this.$router.currentRoute.path !== "/cart" &&
        this.navActiveElement !== "cart"
      ) {
        this.OPEN_CART()
      }
    },
    handleNavSelect() {
      this.SET_NAV_ACTIVE_ELEMENT(null)
    },

    onScroll(/* currentScrollPosition */) {
      // if (this.GET_IS_ABOVE_BREAKPOINT("lg") && !!this.navActiveElement) {
      //   if (currentScrollPosition < 0) {
      //     return
      //   }
      //   if (!this.lastScrollPosition) {
      //     this.lastScrollPosition = currentScrollPosition
      //     console.log("last", this.lastScrollPosition)
      //     return
      //   }
      //   console.log(currentScrollPosition, this.lastScrollPosition)
      //   // Stop executing this function if the difference between
      //   // current scroll position and last scroll position is less than some offset
      //   if (Math.abs(currentScrollPosition - this.lastScrollPosition) < 100) {
      //     return
      //   }
      //   // if it's over the threshold, hide the current nav element
      //   this.handleNavSelect()
      //   this.lastScrollPosition = null
      // }
    },

    setMenuActive(val) {
      if (val) {
        this.SET_NAV_ACTIVE_ELEMENT("menu")
      } else {
        this.SET_NAV_ACTIVE_ELEMENT(null)
      }
    },

    setSearchActive(val) {
      if (val) {
        this.SET_NAV_ACTIVE_ELEMENT("search")
        GTMAPI.trackEvent("search_result.enabled")
      } else {
        this.SET_NAV_ACTIVE_ELEMENT(null)
      }
    }
  },

  computed: {
    ...mapState("ui", ["isNavInverted", "navActiveElement"]),
    ...mapState("scroll", ["scrollPos", "autoScroll", "viewportHeight"]),
    ...mapState("breakpoints", ["currentWidth"]),
    ...mapGetters("breakpoints", [
      "GET_IS_ABOVE_BREAKPOINT",
      "GET_IS_BELOW_BREAKPOINT"
    ]),
    ...mapGetters("content", ["GET_SITE_CONFIG"]),
    ...mapGetters("scroll", ["GET_IS_AUTOSCROLL"]),

    logoLink() {
      return this.GET_SITE_CONFIG.animatedLogo &&
        this.GET_SITE_CONFIG.animatedLogo[0]
        ? this.GET_SITE_CONFIG.animatedLogo[0].original_secure_url
        : false
    },

    navActive() {
      return ["search", "menu"].includes(this.navActiveElement)
    }
  },

  watch: {
    $route(to, from) {
      // Added the null check because it will trigger on
      // page load, which would unset the default value from created()
      if (to !== from && from.name != null) {
        this.handleNavSelect()
      }
    },

    scrollPos(newVal) {
      if (!this.autoScroll) this.onScroll(newVal)
    },

    currentWidth() {
      this.setMenuActive(false)
    }
  }
}
</script>

<style lang="scss">
$logo-height-default: 24px;
$logo-height-md: 26px;
$logo-height-lg: 30px;
$logo-height-xl: 40px;

.bleach-nav {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 10;
  pointer-events: none;

  &__header {
    position: absolute;
    width: 100%;
    height: $nav-height-default;
    z-index: 4;
    transition: background-color 0.2s ease;
    overflow: visible;
    background-color: getcolour(bleach_black);
    pointer-events: all;
    touch-action: none;

    &--inverted {
      background-color: getcolour(bleach_white);
    }

    @include media-breakpoint-up(md) {
      height: $nav-height-md;
    }

    @include media-breakpoint-up(lg) {
      height: $nav-height-lg;
    }

    @include media-breakpoint-up(xl) {
      height: $nav-height-xl;
    }
  }

  &__load-spinner-holder {
    position: absolute;
    width: 100%;
    bottom: 0;
  }

  &__header-interstitial {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 2;
  }

  &__header-content {
    position: relative;
    width: 100%;
    max-width: $bleach-site-max-width;
    margin: 0 auto;
    height: 100%;
    pointer-events: all;
    padding: 0 24px;

    @include media-breakpoint-up(md) {
      padding: 0 32px;
    }

    @include media-breakpoint-up(lg) {
      padding: 0;
      // NB - 'Min' capitalised below to avoid compiler confusion with Sass method
      max-width: Min(#{$bleach-site-max-width}, calc(100vw - (2 * 32px)));
    }

    @include media-breakpoint-up(xl) {
      padding: 0;
    }
  }

  &__header-items {
    flex: 1 0 33%;
    height: 100%;
    pointer-events: none;

    &--logo {
      position: relative;
      width: 60px;
      height: $logo-height-default;
      flex: 1 1 auto;
      // mix-blend-mode: difference;

      @include media-breakpoint-up(md) {
        height: $logo-height-md;
      }

      @include media-breakpoint-up(lg) {
        height: $logo-height-lg;
        width: 100px;
      }

      @include media-breakpoint-up(xl) {
        height: $logo-height-xl;
        // align-self: flex-end;
      }
    }

    @include media-breakpoint-up(lg) {
      height: $logo-height-lg - 2;
    }

    @include media-breakpoint-up(xl) {
      height: $logo-height-xl - 2;
      // align-self: flex-end;
    }
  }

  &__item-holder {
    position: relative;
    // width: 50%;
    max-width: 22px;
    height: 100%;
    display: flex;
    justify-content: flex-start;
    overflow: visible;

    @include media-breakpoint-up(md) {
      max-height: $logo-height-md;
      max-width: $logo-height-md;
    }

    @include media-breakpoint-up(lg) {
      max-height: $logo-height-lg;
      max-width: $logo-height-lg;
    }

    @include media-breakpoint-up(xl) {
      max-height: $logo-height-xl;
      max-width: $logo-height-xl;
    }

    &--end {
      justify-content: flex-end;
    }

    &:not(:last-child) {
      margin-right: 32px;

      @include media-breakpoint-up(lg) {
        margin-right: 40px;
      }
    }
  }

  &__logo {
    fill: $bleach-white;
    mix-blend-mode: difference;

    &-invert {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      transform: scale(1.2);
    }

    &-link {
      pointer-events: all;
      display: inline-block;
      height: 100%;
      width: 100%;
    }
  }

  &__logo-video {
    position: relative;
    width: 100%;
    height: 100%;
    & img,
    & video {
      object-fit: contain;
    }
  }

  &__menu,
  &__search,
  &__cart {
    position: absolute;
    width: 100%;
  }

  &__menu {
    z-index: 1;
  }

  &__search {
    z-index: 2;
  }

  &__cart {
    z-index: 4;
  }

  &__cart-alert {
    position: absolute;
    width: 100%;
    height: $nav-height-default;
    z-index: 2;

    @include media-breakpoint-up(md) {
      height: $nav-height-md;
    }

    @include media-breakpoint-up(lg) {
      height: $nav-height-lg + $menu-height-lg;
    }

    @include media-breakpoint-up(xl) {
      height: $nav-height-xl + $menu-height-lg;
    }
  }
}
</style>
