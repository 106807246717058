var render = function render(){var _vm=this,_c=_vm._self._c;return _c('focus-trap',{attrs:{"active":_vm.navActive}},[_c('nav',{staticClass:"bleach-nav",style:({ height: `${_vm.viewportHeight}px` }),attrs:{"tabindex":"-1"}},[_c('div',{staticClass:"bleach-nav__header",class:{ 'bleach-nav__header--inverted': _vm.isNavInverted },on:{"click":_vm.handleNavSelect}},[_c('div',{staticClass:"bleach-nav__header-content flex--row-center-center"},[_c('div',{staticClass:"bleach-nav__header-items flex--row-start-center"},[(_vm.GET_IS_BELOW_BREAKPOINT('lg'))?[_c('div',{staticClass:"bleach-nav__item-holder"},[_c('MenuControl',{attrs:{"active":_vm.navActiveElement === 'menu'},on:{"click":_vm.handleMenuToggle}})],1),(_vm.GET_IS_BELOW_BREAKPOINT('md'))?_c('div',{staticClass:"bleach-nav__item-holder"},[_c('AccountControl',{attrs:{"active":_vm.navActiveElement === 'login'}})],1):_vm._e()]:_vm._e(),(_vm.GET_IS_ABOVE_BREAKPOINT('lg'))?_c('div',{staticClass:"bleach-nav__item-holder"},[_c('StoreControl',{staticClass:"bleach-nav__store-control",attrs:{"light":_vm.isNavInverted}})],1):_vm._e()],2),_c('div',{staticClass:"bleach-nav__header-items bleach-nav__header-items--logo flex--col-center-center"},[_c('LinkTag',{staticClass:"bleach-nav__logo-link",attrs:{"link":`/`},nativeOn:{"click":function($event){return _vm.handleNavSelect.apply(null, arguments)}}},[(_vm.isNavInverted)?_c('MediaItem',{attrs:{"elementId":'nav_inverted_logo'},scopedSlots:_vm._u([{key:"default",fn:function(slotProps){return [(
                    slotProps.mediaItem &&
                      slotProps.mediaItem.image &&
                      slotProps.mediaItem.image[0]
                  )?_c('bleach-image',{staticClass:"bleach-nav__logo-invert",attrs:{"src":slotProps.mediaItem.image[0].original_secure_url,"sizes":{
                    md: ['auto', 24],
                    lg: ['auto', 26],
                    xl: ['auto', 30]
                  },"contain":"","default-size":['auto', 40],"imageQuality":"60"}}):_vm._e()]}}],null,false,2553024154)}):[(_vm.logoLink)?_c('bleach-image',{staticClass:"bleach-nav__logo-video",attrs:{"src":_vm.logoLink,"sizes":{
                  md: ['auto', 24],
                  lg: ['auto', 26],
                  xl: ['auto', 30]
                },"default-size":['auto', 40],"imageQuality":"60"}}):_c('BleachLogo',{staticClass:"bleach-nav__logo"})]],2)],1),_c('div',{staticClass:"bleach-nav__header-items flex--row-end-center"},[(_vm.GET_IS_ABOVE_BREAKPOINT('md'))?_c('div',{staticClass:"bleach-nav__item-holder bleach-nav__item-holder--end"},[_c('AccountControl',{attrs:{"active":_vm.navActiveElement === 'login'}})],1):_vm._e(),_c('div',{staticClass:"bleach-nav__item-holder bleach-nav__item-holder--end"},[_c('SearchControl',{attrs:{"active":_vm.navActiveElement === 'search'},on:{"click":_vm.handleSearchToggle}})],1),_c('div',{staticClass:"bleach-nav__item-holder bleach-nav__item-holder--end"},[_c('CartControl',{on:{"click":_vm.handleCartTrigger}})],1)])]),_c('div',{staticClass:"bleach-nav__load-spinner-holder"},[_c('LoadSpinner')],1)]),_c('div',{staticClass:"bleach-nav__menu"},[_c('NavMenu',{ref:"navMenu",attrs:{"active":_vm.navActiveElement === 'menu'},on:{"activated":_vm.setMenuActive,"select":_vm.handleNavSelect}})],1),_c('div',{staticClass:"bleach-nav__search"},[_c('NavSearch',{ref:"navSearch",attrs:{"active":_vm.navActiveElement === 'search'},on:{"activated":_vm.setSearchActive,"select":_vm.handleNavSelect}})],1),_c('div',{staticClass:"bleach-nav__cart"},[_c('Cart',{ref:"cart",attrs:{"active":_vm.navActiveElement === 'cart'}})],1),_c('div',{staticClass:"bleach-nav__cart-alert",class:{
        'bleach-nav__cart-alert--std-pos': _vm.navActiveElement === 'search'
      }},[_c('CartAlert')],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }