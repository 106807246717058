<template>
  <div class="load-spinner">
    <div class="load-spinner__bar" ref="bar"></div>
  </div>
</template>

<script>
import { gsap } from "gsap"
import { Cubic, CSSPlugin } from "gsap/all"
gsap.registerPlugin(CSSPlugin)

import { mapState, mapGetters } from "vuex"

export default {
  data: () => ({}),

  // mounted() {
  //   this.doAnimation()
  // },

  computed: {
    ...mapState("ui", ["routeTransitionState"]),
    ...mapGetters("content", ["GET_VIEWPORT_IMAGES_ARE_LOADED"])
  },

  watch: {
    routeTransitionState(newVal) {
      if (newVal) {
        this.debug.warn("TRIGGER SPINNER")
        this.doAnimation()
      }
    }
  },

  methods: {
    doAnimation() {
      const bar = this.$refs.bar
      const tl = gsap.timeline()
      tl.to(bar, {
        duration: 0,
        width: 0,
        xPercent: 0
      })
        .to(bar, {
          duration: 0.5,
          width: "100%",
          ease: Cubic.easeInOut
        })
        .to(bar, {
          duration: 0.5,
          xPercent: 100,
          ease: Cubic.easeInOut
        })
        .then(() => {
          console.log("THIS?", this)
          if (!this.GET_VIEWPORT_IMAGES_ARE_LOADED) {
            this.doAnimation()
          }
        })
    }
  }
}
</script>

<style lang="scss">
.load-spinner {
  position: absolute;
  width: 100%;
  bottom: 0;
  height: 4px;
  overflow: hidden;

  &__bar {
    position: absolute;
    width: 0;
    height: 100%;
    top: 0;
    left: 0;
    transform-origin: left;
    background-color: getcolour(bleach_lime);
  }
}
</style>
